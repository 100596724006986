<template>
  <div>
    <p class="clearfix mb-0 text-center">
      <span class="sizeFont mt-25">
        DUNI Gestão &amp; Tecnologia - COPYRIGHT ©
        {{ new Date().getFullYear() }}. Todos os direitos reservados.
      </span>
    </p>
    <p class="clearfix mb-0 text-center">
      <span class="sizeFont">
        D Goncalves Gestao e Tecnologia LTDA, CNPJ: 45.927.546/0001-20.</span>
    </p>
    <!-- <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://cienciadocomportamento.com.br"
        target="_blank"
      >
      </b-link>
      <span class="d-none d-sm-inline-block">
        CNPJ: 45.927.546/0001-20, NIRE: 35238895679, Todos os direitos
        reservados</span>
    </span>

    <span class="float-md-right d-none d-md-block">FMX Soluções em Tecnologia | Feito com
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span>
  </p> -->
  </div>
</template>

<script>
import { BLink } from "bootstrap-vue"

export default {
  components: {
    // BLink
  }
}
</script>
<style scoped>
.sizeFont {
  font-size: 0.9em;
}
</style>
